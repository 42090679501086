import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { RiLock2Fill } from "react-icons/ri";
import "./page.css";
import PasswordInputField from "../Services/Utils/Passwordverify/password";
import Error from "../Components/Modal/Error";
import ConfirmPasswordInputField from "../Services/Utils/Passwordverify/Confirmpassword";
import LoadVeriy from "../Components/Loader/LoadVeriy";
import Confirmation from "../Components/Modal/Confirmation";
import { useTranslation } from "react-i18next";
import { API, NodeServer_API } from "../server";
import { toast } from "react-toastify";

export default function Loginpg({ currentpage }) {
  const [loading, setLoading] = useState(2);
  const [currentState, setCurrentState] = useState("");
  const [loaderror, setLoaderror] = useState({
    statusid: 0,
    message: "",
  });
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["Token"]);
  const [emailvalue, setEmailvalue] = useState("");

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
    otp: "",
  });

  const [emailfield, setEmailfield] = useState("");
  const [passwordfield, setPasswordfield] = useState("");

  const [loadVerify, setLoadVerify] = useState(0);
  const [sendemail, setSendemail] = useState(0);
  // const [expiry, setExpiry] = useState(new Date())

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setCurrentState("0");
    setCookie("Token", "undefined", { path: "/" });
    currentpage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///********** Handle Submit Button ********///
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoaderror({
        statusid: 1,
        message: "Loading...",
      });
      const obj = {
        email: emailfield,
        password: passwordfield,
      };
      if (obj.email !== "" && obj.password !== "") {
        await axios
          .post(`${NodeServer_API}/agents/login`, obj)
          .then((response) => {
            if (response.data.code === 200) {
              setLoaderror({
                statusid: 1,
                message: "Loading...",
              });
              const objct = {
                // role: response.data.role,
                status: response.data.code,
              };
              navigate("/dashboard", { state: objct });
              setCookie("Token", response.data.token, { path: "/" }); // Set Token To the Cookie Value and make it accessible to all routes
            }
          });
      }
    } catch (e) {
      setLoading(2);
      catchedError(e);
    }
  };

  ///****** Send OTP to Email Address ******///
  const handleOTPSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoaderror({
        statusid: 1,
        message: "Sending Confirmation Email",
      });

      setSendemail(1);
      const obj = { email: emailvalue };
      // await axios.post(`${API}/agent/resetPassword`, obj).then((response) => {
      await axios
        .post(`${NodeServer_API}/agents/resetPassword`, obj)
        .then((response) => {
          if (response.data.success === true) {
            setLoaderror({
              statusid: 4,
              message: "An OTP Has been sent to your Email",
            }); // Email Sent
            // setLoading(4)                             // Move To The Next Page
          } else {
            setSendemail(3); // Unable to send Email
          }
        });
    } catch (e) {
      setLoading(3);
      catchedError(e);
    }
  };

  ///******* Handle Reset Submit *******////
  const handleResetSubmit = async (e) => {
    try {
      e.preventDefault();

      // If Confirm password Error is not returning any error and Confirm password Field is not empty, that suggest a password match
      if (
        confirmPasswordError === "" &&
        passwordError === "" &&
        passwordInput.confirmPassword !== "" &&
        passwordInput.password !== "" &&
        passwordInput.otp !== ""
      ) {
        setLoadVerify(1);
        const obj = {
          password: passwordInput.confirmPassword,
          otp: passwordInput.otp,
          email: emailvalue,
        };

        await axios
          .post(`${NodeServer_API}/agents/resetPassword`, obj)
          .then((response) => {
            if (response.data.success === true) {
              setLoadVerify(2);
            } else {
              setLoadVerify(3);
            }
          });
      }
    } catch (e) {
      setLoadVerify(0);
      toast.error(e.response.data.message);
    }
  };

  const catchedError = (ex) => {
    if (ex.code === "ERR_NETWORK") {
      setLoaderror({
        statusid: 2,
        message: "Connection Error, check your Network",
      });
    } else if (
      ex.message.includes("500") ||
      ex.message.includes("502") ||
      ex.message.includes("503") ||
      ex.message.includes("504")
    ) {
      setLoaderror({
        statusid: 3,
        message: ex.response.data.message,
      }); // Bad response 500
    } else if (
      ex.message.includes("400") ||
      ex.message.includes("401") ||
      ex.message.includes("403") ||
      ex.message.includes("404") ||
      ex.message.includes("408")
    ) {
      setLoaderror({
        statusid: 3,
        message: ex.response.data.message,
      }); // Bad response 400
    }
  };

  ///******** Handle Fields Onchange *******///
  const handleLoginChange = (e) => {
    if (e.target.name === "email") {
      setEmailfield(e.target.value);
      // console.log(e.target.value)
    }

    if (e.target.name === "password") {
      setPasswordfield(e.target.value);
      // console.log(e.target.value)
    }
  };

  ///********** Verify That OTP is confirmed ********///
  const Bnwcnpotpfield = async (e) => {
    setPasswordInput({ otp: e.target.value });
  };

  //// ****** CLOSE All Error/Confirmation Overlay ****** ///
  const handleClose = (incoming) => {
    if (incoming === 2) {
      setLoading(4);
    } else {
      setSendemail(0);
      setLoaderror({
        statusid: 0,
        message: "",
      });
    }
  };

  const handleCloseConf = () => {
    setLoadVerify(0);
    setSendemail(0);
    window.location.reload(false);
  };

  const handleVisIcon = () => {
    setVisible(!visible);
  };

  const handleGoBack = () => {
    // setLoading(2)
    // setPasswordfield("")
    // setEmailvalue("")
    window.location.reload(false);
  };

  const handleForgotpass = () => {
    setLoading(3);
    setEmailvalue("");
  };

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
    // console.log(`setPass: ${JSON.stringify(NewPasswordInput)}`)
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword?.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handlebnwemailconfm = (e) => {
    setEmailvalue(e.target.value);
    // console.log(e.target.value)
  };

  const handleFocus = () => {
    setLoaderror({
      statusid: 0,
      message: "",
    });
  };

  return (
    <div className="bnw-login-contain">
      {loading === 2 ? (
        <div className="signinContain">
          {loaderror.statusid === 1 ? (
            <LoadVeriy type="spin" text={loaderror.message} />
          ) : loaderror.statusid === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={loaderror.message}
              icon="5"
            />
          ) : (
            ""
          )}
          <form className="bnw-login-body" onSubmit={handleSubmit}>
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>{t("Seconnecter")}</span>
            <input
              onFocus={handleFocus}
              className="bnw-ligin-email"
              name="email"
              placeholder={t("emailaddressplacehold")}
              value={emailfield}
              type="email"
              onChange={handleLoginChange}
            />

            <div className="bnw-ligin-email-container">
              <input
                onFocus={handleFocus}
                className="bnw-ligin-password"
                name="password"
                placeholder={t("password")}
                value={passwordfield}
                type={visible ? "password" : "text"}
                onChange={handleLoginChange}
              />
              {visible ? (
                <MdOutlineVisibility id="visib-icon" onClick={handleVisIcon} />
              ) : (
                <MdOutlineVisibilityOff
                  id="visib-icon"
                  onClick={handleVisIcon}
                />
              )}
            </div>

            {loaderror.statusid === 3 ? (
              <div className="errorpopup">
                <span>{loaderror.message}</span>
              </div>
            ) : (
              ""
            )}

            <div className="forgotpass-cont">
              <span onClick={handleForgotpass}>{t("forgotpassword")}?</span>
            </div>
            <input
              className="bnw-login-submit"
              type="submit"
              value={t("SECONNECTER")}
            />
          </form>
        </div>
      ) : loading === 3 ? (
        <div className="signinContain">
          {loaderror.statusid === 1 ? (
            <LoadVeriy type="spin" text={loaderror.message} />
          ) : loaderror.statusid === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={loaderror.message}
              icon="5"
            />
          ) : loaderror.statusid === 4 ? (
            <Confirmation
              closeOverlay={handleClose}
              message={loaderror.message}
              icon="1"
            />
          ) : (
            ""
          )}

          <form className="bnw-login-body" onSubmit={handleOTPSubmit}>
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>{t("resetpassword")}</span>

            <div className="resetpass-bnw">
              <span>{t("resetpasstext")}</span>
            </div>

            <input
              onFocus={handleFocus}
              className="bnw-ligin-email"
              placeholder={t("emailaddressplacehold")}
              value={emailvalue}
              onChange={handlebnwemailconfm}
              type="email"
            />

            {loaderror.statusid === 3 ? (
              <div className="errorpopup">
                <span>{loaderror.message}</span>
              </div>
            ) : (
              ""
            )}

            <div className="forgotpass-cont">
              <span onClick={handleGoBack}>{t("goback")}</span>
            </div>

            <input
              className="bnw-login-submit"
              type="submit"
              value={t("otptext")}
            />
          </form>
        </div>
      ) : (
        <div className="signinContain h-full py-5">
          {loadVerify === 1 ? (
            <LoadVeriy type="spin" text="Verifying OTP" />
          ) : loadVerify === 2 ? (
            <Error
              closeOverlay={handleCloseConf}
              message={"Password Changed Successfully"}
              icon="1"
            />
          ) : loadVerify === 3 ? (
            <Error
              closeOverlay={handleClose}
              message={"Invalid OTP"}
              icon="2"
            />
          ) : (
            ""
          )}
          <form className="bnw-login-body" onSubmit={handleResetSubmit}>
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>Create New Password</span>
            <input
              className="bnw-ligin-email"
              placeholder="2FA-Code"
              value={passwordInput.otp}
              handleGoBacktype="text"
              onChange={Bnwcnpotpfield}
            />

            <div className="bnw-ligin-email-container">
              <PasswordInputField
                handlePasswordChange={handlePasswordChange}
                handleValidation={handleValidation}
                passwordValue={passwordInput.password}
                passwordError={passwordError}
              />
            </div>

            <div className="bnw-ligin-email-container">
              <ConfirmPasswordInputField
                handlePasswordChange={handlePasswordChange}
                handleValidation={handleValidation}
                confirmPasswordValue={passwordInput.confirmPassword}
                confirmPasswordError={confirmPasswordError}
              />
            </div>

            <div className="forgotpass-cont">
              <span onClick={handleGoBack}>Back To Home</span>
            </div>

            <input
              className="bnw-login-submit"
              type="submit"
              value="Confirm Password Change"
            />
          </form>
        </div>
      )}
    </div>
  );
}
