import React, { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import "./Singleselect.css";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

const Bankdropdown = ({ selectedSocVal, placeholder, socialjs }) => {
  // console.log("my banks: ", socialjs);
  const [social, setSocial] = useState(false);
  const [values, setValues] = useState("");

  const { bankdetails } = useSelector((state) => state.datareponse);
  let accountdetails = bankdetails;

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleSocialClick = (e, incoming) => {
    setValues(incoming.soc);
    setSocial(!social);
    selectedSocVal(incoming);
  };

  let filteredList = socialjs?.filter(
    (items) =>
      items?.name?.toLowerCase()?.includes(values?.toLowerCase()) ||
      items?.momoname?.toLowerCase()?.includes(values?.toLowerCase())
  );

  // momoname

  const handleValueChange = (e) => {
    setValues(e.target.value);
  };

  return (
    <div className="w-[70%] relative min-w-[190px] phone_screen:w-full">
      <input
        disabled={accountdetails.length > 1 ? true : false}
        value={values}
        onClick={handleSocialFocus}
        onChange={handleValueChange}
        placeholder={placeholder}
        className="input_field w-full outline-none p-2 text-[13px]"
      />

      <IoChevronDown id="chevron-down" onClick={handleSocialFocus} />

      {social && (
        <div
          className="w-full absolute py-1 bg-[white] flex flex-col justify-start max-h-[150px] z-10 top-[38px] overflow-y-scroll border"
          onMouseLeave={handleSocialFocus}
        >
          {filteredList
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((item, index) => (
              <span
                onClick={(e) =>
                  handleSocialClick(e, {
                    soc: item.momoname || item.name,
                    rn: item.momoname || item.code,
                  })
                }
                key={index}
                className="p-1 px-2 hover:bg-[#ccc4] border-b-2 text-[13px] text-[black]"
              >
                {item.momoname || item.name}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};

export default Bankdropdown;
