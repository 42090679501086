import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import axios from "axios";
import { NodeServer_API } from "../server";
import { Store } from "../Redux/store";
import { transactionRecord } from "../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
import { loadingData, modalToggle } from "../Redux/Actions/buttonclicks";
import { useCookies } from "react-cookie";

const PaymentConfirmation = () => {
  const [cookies] = useCookies(["Token"]);

  const { transactionhistorydata, userdata } = useSelector(
    (state) => state.datareponse
  );

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxes = (id) => {
    if (selectedTransactions.includes(id)) {
      setSelectedTransactions((prev) => prev.filter((item) => item !== id));
    } else {
      setSelectedTransactions((prev) => [...prev, id]);
    }
  };

  const handleCheckAll = () => {
    if (selectAll) {
      setSelectedTransactions([]);
    } else {
      const allTransactionIds = transactionhistorydata
        .filter(
          (tdata) =>
            tdata.user_id === userdata.ID &&
            (tdata.status === "Successful" || tdata.status === "Pending") &&
            tdata.recipient_status === 0
        )
        .map((tdata) => tdata.id);
      setSelectedTransactions(allTransactionIds);
    }
    setSelectAll(!selectAll);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    Store.dispatch(
      loadingData({
        status: true,
        text: "Processing Transaction...",
        type: "spin",
      })
    );

    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/transactionHistory/updateHistory`,
        data: { transactionIds: selectedTransactions },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success) {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
          toast.success(response.data.message);
          Store.dispatch(transactionRecord(response.data.data));
        }
      });
    } catch (error) {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      console.error("Error updating transaction history", error);
    }
  };

  const handleClose = () => {
    Store.dispatch(modalToggle(false));
  };

  return (
    <div className="w-full h-screen fixed top-0 flex justify-center items-center bg-greybackground z-20">
      <div className="min-w-[350px] w-[45%] bg-[white] h-[50%] max-h-[500px] overflow-y-auto min-h-[350px] flex flex-col gap-3 items-center p-4 relative">
        <span className="font-bold text-[23px] mb-5">Payment Confirmation</span>
        <IoCloseCircleOutline
          onClick={handleClose}
          size={25}
          className="absolute cursor-pointer top-4 right-4"
        />

        <form className="flex flex-col gap-1 justify-center items-end">
          <table>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Reason</th>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleCheckAll}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionhistorydata
                .filter(
                  (tdata) =>
                    tdata.user_id === userdata.ID &&
                    (tdata.status === "Successful" ||
                      tdata.status === "Pending") &&
                    tdata.recipient_status === 0
                )
                .map((tdata, index) => (
                  <tr key={index}>
                    <td>{tdata.currency + " " + tdata.claims_equivalent}</td>
                    <td>{tdata.transfer_reason}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedTransactions.includes(tdata.id)}
                        onChange={() => handleCheckboxes(tdata.id)}
                        className="cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <button
            onClick={handleConfirm}
            disabled={selectedTransactions.length === 0} // Disable when no transactions selected
            className={`rounded-md px-4 py-2 text-[13px] font-semibold ${
              selectedTransactions.length > 0
                ? "bg-[green] text-[white] cursor-pointer"
                : "bg-gray-400 text-gray-700 cursor-not-allowed"
            }`}
          >
            CONFIRM
          </button>
        </form>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
