import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { NodeServer_API } from "../server";
import { Store } from "../Redux/store";
import { modalToggle } from "../Redux/Actions/buttonclicks";

const Support = () => {
  const { userdata } = useSelector((state) => state.datareponse);

  const [formData, setFormData] = useState({
    name: userdata.fname + " " + userdata.lname,
    email: userdata.email,
    phone: userdata.phone,
    category: "",
    priority: "",
    subject: "",
    description: "",
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value, // Handle file input separately
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data for submission
    const formToSubmit = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formToSubmit.append(key, value);
    }

    try {
      const response = await axios(`${NodeServer_API}/support/tickets`, {
        method: "POST",
        body: formToSubmit,
      });

      if (response.data.success) {
        // Handle success
        const result = await response.json();
        toast.success(response.data.message);

        console.log("Ticket submitted successfully:", result);
        toast.success("Ticket raised successfully!");
      } else {
        // Handle error response
        const error = await response.json();
        console.error("Error submitting ticket:", error);
        toast.error("Error raising ticket. Please try again.");
      }
    } catch (err) {
      console.error("Request failed:", err);
      alert("An error occurred while submitting the ticket.");
    }
  };

  const handleClose = () => {
    Store.dispatch(modalToggle(""));
  };

  return (
    <div className="w-full bg-[#0000003b] h-screen flex justify-center items-center fixed top-0 z-20">
      <div className="w-[70%] bg-[white] h-[80vh] px-3 gap-6 py-8 flex flex-col overflow-y-auto">
        <div className="w-full  flex items-center justify-center relative">
          <span className="text-[20px] font-bold">QUICK SUPPORT</span>
          <IoCloseCircleOutline
            size={25}
            className="cursor-pointer absolute right-0"
            onClick={handleClose}
          />
        </div>

        <form
          onSubmit={handleFormSubmit}
          className="w-full flex flex-col gap-2 p-4 border rounded-lg"
        >
          {/* Category */}
          <label htmlFor="category" className="font-semibold">
            Issue Category:
          </label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            className="border p-2 rounded text-[14px] outline-slate-200"
            required
          >
            <option value="">Select a category</option>
            <option value="technical">Technical Issue</option>
            <option value="billing">Payment Issue</option>
            <option value="general">General Inquiry</option>
          </select>

          {/* Issue Priority */}
          <label htmlFor="priority" className="font-semibold">
            Priority:
          </label>
          <select
            id="priority"
            name="priority"
            value={formData.priority}
            onChange={handleInputChange}
            className="border p-2 rounded text-[14px] outline-slate-200"
            required
          >
            <option value="">Select priority</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>

          {/* Subject */}
          <label htmlFor="subject" className="font-semibold">
            Subject:
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            placeholder="Brief description of the issue"
            className="border p-2 rounded text-[14px] outline-slate-200"
            required
          />

          {/* Issue Description */}
          <label htmlFor="description" className="font-semibold">
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Describe your issue in detail"
            className="border p-2 rounded text-[14px] outline-slate-200 h-32"
            required
          ></textarea>

          {/* File Upload */}
          <label htmlFor="file" className="font-semibold">
            Attach File (optional):
          </label>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleInputChange}
            className="border p-2 rounded text-[14px] outline-slate-200"
          />

          {/* Submit Button */}
          <input
            type="submit"
            value="SUBMIT"
            className="bg-blue-500 font-bold text-white p-2 rounded cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
};

export default Support;
