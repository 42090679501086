import { createAction, createReducer } from "@reduxjs/toolkit";

const PaystackWalletBalance = createAction("PaystackWalletBalance");

const initialState = {
  paystackWalletBalance: [],
};

export const paystackReducer = createReducer(initialState, (builder) => {
  builder.addCase(PaystackWalletBalance, (state, action) => {
    state.paystackWalletBalance = action.payload;
  });
});
