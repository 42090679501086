import React from "react";
import "./Sidebar.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TopSidebar = ({ menuClicked }) => {
  const { t } = useTranslation();
  const { currenttab } = useSelector((state) => state.buttonclicks);

  const handleMenuClick = () => {
    menuClicked();
  };

  return (
    <div className="bnw-tpsbar-main-container">
      <div className="bnw-tpsbar-container">
        <div className="bnw-menu-Container">
          <HiMenu id="bnw-himenu-icon" onClick={handleMenuClick} />
          {currenttab === 1 ? (
            <span id="bnw-tsb-dashb">Dashboard</span>
          ) : currenttab === 2 ? (
            <span id="bnw-tsb-dashb">Claims</span>
          ) : currenttab === 3 ? (
            <span id="bnw-tsb-dashb">Promo</span>
          ) : currenttab === 5 ? (
            <span id="bnw-tsb-dashb">Settings</span>
          ) : (
            <span id="bnw-tsb-dashb">Profile</span>
          )}
        </div>
        <div className="bnw-searchbox-container">
          <div className="bnw-search-box-contain">
            <input placeholder={t("searchcontent")} id="bnw-search-box" />
            <BiSearchAlt2 id="bnw-search-icon" />
          </div>
          <div className="bnw-profile">
            <div className="bnw-profileImg-container">
              <div id="bnw-profileImg" />
            </div>
            {/* <div className='bnw-profile-name'>
                        <span>{name}</span>
                        <span>{role}</span>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSidebar;
