import React from 'react'
import './Loading.css'
import ReactLoading from 'react-loading';

const LoadVeriy = ({type, text}) => {
  return (
    <div className="bnw-loadercontainer">
        <div className="bnw-loadingBox">
            <ReactLoading type={type} color={'#e50000'} height={'60%'} width={'60%'} />
            <span>{text}</span>
        </div>
    </div>
  )
}

export default LoadVeriy