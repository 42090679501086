import React from 'react'
import './page.css'

const Promo = () => {
  return (
    <div className='bnw-promopg-container'>
      Promo
    </div>
  )
}

export default Promo