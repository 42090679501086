export const getBankDetails = (bankdetailsdata) => ({
  type: "BankDetailsdata",
  payload: bankdetailsdata,
});

export const getExchangeRate = (exchangeratedata) => ({
  type: "ExchangeRateData",
  payload: exchangeratedata,
});

export const getPoints = (pointsdata) => ({
  type: "Pointsdata",
  payload: pointsdata,
});

export const getUserData = (userdata) => ({
  type: "Userdata",
  payload: userdata,
});

export const getPaystackBanks = (bakdetails) => ({
  type: "PaystackBanks",
  payload: bakdetails,
});

export const getSeerbitsBanks = (seerbitbanks) => ({
  type: "SeerbitsProvider",
  payload: seerbitbanks,
});

export const transactionRecord = (transactionhistory) => ({
  type: "TransactionRecord",
  payload: transactionhistory,
});

export const claimsRecord = (claimsdata) => ({
  type: "ClaimsRecord",
  payload: claimsdata,
});

export const seerbitPocketData = (pocketdata) => ({
  type: "SeerbitPocketData",
  payload: pocketdata,
});

export const getPaymentOptions = (paymentoptions) => ({
  type: "AvailablePaymentoptions",
  payload: paymentoptions,
});

export const getAllRegions = (regionsList) => ({
  type: "RegionsList",
  payload: regionsList,
});

export const getRegionPayment = (payment) => ({
  type: "RegionPaymentoption",
  payload: payment,
});
