import { createAction, createReducer } from "@reduxjs/toolkit";

const CurrentTab = createAction("CurrentTab");
const PageStatus = createAction("PageStatus");
const Side_Bar_Click = createAction("Side_Bar_Click");
const Claims_Bar_Status = createAction("Claims_Bar_Status");
const ToggleModal = createAction("ToggleModal");
const Loding_Modal = createAction("Loding_Modal");
const Tab_State = createAction("Tab_State");
const Add_New_Modal = createAction("Add_New_Modal");

const initialState = {
  currenttab: 1,
  sidebartab: "Main",
  claimstabstatus: 0,
  togglemodal: "",
  isLoading: {},
  deactivate: true,
  newmodal: {
    status: 0,
    value: "",
  },
};

export const buttonclickReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CurrentTab, (state, action) => {
      state.currenttab = action.payload;
    })

    .addCase(PageStatus, (state, action) => {
      state.currenttab = action.payload;
    })

    .addCase(Side_Bar_Click, (state, action) => {
      state.sidebartab = action.payload;
    })

    .addCase(Claims_Bar_Status, (state, action) => {
      state.claimstabstatus = action.payload;
    })

    .addCase(Loding_Modal, (state, action) => {
      state.isLoading = action.payload;
    })

    .addCase(ToggleModal, (state, action) => {
      state.togglemodal = action.payload;
    })

    .addCase(Tab_State, (state, action) => {
      state.deactivate = action.payload;
    })

    .addCase(Add_New_Modal, (state, action) => {
      state.newmodal = action.payload;
    });
});
