const Currencycode = {
  BJ: "XOF",
  BF: "XOF",
  GH: "GHC",
  GN: "XOF",
  CI: "XOF",
  LR: "LYD",
  ML: "XOF",
  MR: "MRU",
  NE: "XOF",
  NG: "NGN",
  SN: "XOF",
  SL: "SLE",
  TG: "XOF",
  CM: "XAF",
  GA: "XAF",
  GM: "GMD",
};
export default Currencycode;
