import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css'
 
const Loader = ({ type, color, text }) => (
    <div className="loadercontainer">
        <div className="loadingBox">
            <ReactLoading type={type} color={'grey'} height={'50%'} width={'50%'} />
            <span id="Loadingtag">{text}</span>
        </div>
    </div>
);
 
export default Loader;