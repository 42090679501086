import React, { useState, useEffect } from "react";
import "./Cards.css";
import Currencycode from "./currencycode";

const Claimcards = ({
  backcolor,
  text,
  value,
  maximum,
  gradient,
  piebackcolor,
  points,
  exchrate,
  countryCode,
}) => {
  // console.log(countryCode)
  const [PercentValue, setPercentValue] = useState();
  const [claimCount, setClaimCount] = useState();

  useEffect(() => {
    SetActiveClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const SetActiveClaims = () => {
    setClaimCount(value); // Value of Claim Count coming from the DB
    setPercentValue((claimCount / maximum) * 100);
  };
  let currencyCode = Currencycode[countryCode];
  // console.log(countryCode)

  return (
    <div
      className="bnw-claimcard-container gap-2"
      style={{ backgroundColor: backcolor }}
    >
      <div className="w-full py-2 h-[60px] flex justify-center items-start">
        <span id="bnw-active-claim">{text}</span>
      </div>
      <div
        className="bnw-active-claim-chat-1"
        style={{
          animationDelay: `-${PercentValue}s`,
          backgroundColor: piebackcolor,
          backgroundImage: `linear-gradient(to right, transparent 50%, ${gradient} 0)`,
        }}
      >
        <div
          className="bnw-active-claim-chat-2"
          style={{ backgroundColor: backcolor }}
        >
          <span id="bnw-active-claim-numb">{claimCount}</span>
        </div>
      </div>
      <div className="currency-conv-container">
        <span>{/* Approx {currencyCode} {exchrate} */}</span>
      </div>
    </div>
  );
};

export default Claimcards;
