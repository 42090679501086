import React, { useState, useEffect } from "react";
import "./Register.css";
import "../page.css";
import {
  MdContactPhone,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { IoIosPerson, IoIosMail } from "react-icons/io";
import Error from "../../Components/Modal/Error";
import LoadVeriy from "../../Components/Loader/LoadVeriy";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { RiLock2Fill } from "react-icons/ri";
import { BsBuilding } from "react-icons/bs";
import GenderJson from "./GenderJson";
import Singleselect from "./Singleselectt";
// import RegionsJson from "./RegionJson";
import { FaChevronDown } from "react-icons/fa";
import dailingCode from "./dailingcode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NodeServer_API } from "../../server";

const Register = ({ currentpage }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    currentpage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to Check if email correspont to sabre email format
  // const FormatEmail = (checkemail) => {
  //   let checkstatus;
  //   let trackedPoint = checkemail.length - 12;

  //   if (checkemail.substring(trackedPoint).toLowerCase() === "sabrecwa.com") {
  //     checkstatus = true;
  //   } else {
  //     checkstatus = false;
  //   }

  //   return checkstatus;
  // };

  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    mname: "",
    rgcode: "",
    agnc_name: "",
    dob: "",
    gender: "",
    email: "",
    password: "",
    confpass: "",
    dailcode: 0,
    phone: "",
    promocode: "",
    pcc: "",
  });

  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);
  const [visibleAlt, setVisibleAlt] = useState(true);
  const [loaderror, setLoaderror] = useState(0);
  const [messageStatus, setMessageStatus] = useState("");
  const [view, setView] = useState(true);
  const [errornotice, setErrornotice] = useState(0);
  const navigate = useNavigate();

  ///********** Handle Submit Button ********///
  const handleSubmit = async (e) => {
    let first_name = "";
    let last_name = "";
    let middle_name = "";

    try {
      e.preventDefault();
      setLoaderror(1);

      let nameAgent = fields.fname.split(" ");
      if (nameAgent.length >= 3) {
        first_name = nameAgent[0];
        last_name = nameAgent[1];
        middle_name = nameAgent[2];
      } else if (nameAgent.length === 2) {
        first_name = nameAgent[0];
        last_name = nameAgent[1];
        middle_name = "None";
      } else {
        first_name = nameAgent[0];
        last_name = "None";
        middle_name = "None";
      }

      const obj = {
        first_name,
        last_name,
        middle_name,
        pcc: fields.pcc,
        region_code: fields.rgcode,
        agency_name: fields.agnc_name,
        dob: fields.dob,
        gender: fields.gender,
        email: fields.email.toLowerCase(),
        password: fields.password === fields.confpass ? fields.password : "",
        dailcode: fields.dailcode,
        phone: dailvalue + "" + fields.phone,
        promo_code: fields.promocode,
      };

      if (obj.email !== "" && obj.password !== "") {
        // Check if form field contains email and password
        // if it contains email, check if the email conforms with @sabrecwa.com standard
        // if (FormatEmail(obj.email)) {
        if (obj.middle_name !== "" && obj.last_name !== "") {
          await axios
            .post(`${NodeServer_API}/agents/register`, obj)
            .then((response) => {
              if (response.data.success === true) {
                // Clear the form fields
                setFields({
                  fname: "",
                  lname: "",
                  mname: "",
                  rgcode: "",
                  agnc_name: "",
                  dob: "",
                  gender: "",
                  email: "",
                  password: "",
                  confpass: "",
                  dailcode: 0,
                  phone: "",
                  promocode: "",
                  pcc: "",
                });

                setView(true);

                setLoaderror(0);
                toast.success(response.data.message);
              }
            });
        } else {
          // Pls enter fullname
          setLoaderror(4);
        }
        // } else {
        //   console.log("Invalid");
        //   setMessageStatus("Email not a valid sabre email");
        //   setLoaderror(2);
        // }
      } else {
        // Pls ensure passwords match
        setLoaderror(0);
        toast.error("Password Mis-match");
      }
    } catch (e) {
      // console.log(e.response);
      setLoaderror(3);
      toast.error(e.response.data.message);
    }
  };

  // Verify PCC before submitting
  const handleVerifyPcc = async (value) => {
    // console.log(value);
    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/verifypcc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { pcc: value },
      };

      await axios(config).then((response) => {
        // console.log(response.data);
        setErrornotice(0);
        setView(false);

        // Use functional form to update state
        setFields((prevFields) => ({
          ...prevFields,
          pcc: response.data.data.pcc,
          agnc_name: response.data.data.agency_name,
          rgcode: response.data.data.country_code,
        }));
      });
    } catch (e) {
      setErrornotice(2);
      setView(true);
    }
  };

  const handleVisIcon = () => {
    setVisible(!visible);
  };

  const handleVisIcon_alt = () => {
    setVisibleAlt(!visibleAlt);
  };

  ///******** Handle Fields Onchange *******///
  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    const re = /^[0-9]+$/;

    if (
      name === "email" ||
      name === "password" ||
      name === "confpass" ||
      name === "fname" ||
      name === "gender"
    ) {
      setFields({ ...fields, [name]: value });
    } else if (name === "phone") {
      if (event.target.value === "" || re.test(event.target.value)) {
        if (event.target.value.length <= 10) {
          setFields({ ...fields, [name]: value });
        }
      }
    } else if (name === "pcc") {
      if (event.target.value.length !== 4) {
        setFields({ ...fields, [name]: value });
        setView(true);
        setErrornotice(1);
      } else {
        setFields({ ...fields, [name]: value });
        setErrornotice(0);
        handleVerifyPcc(value);
      }
    } else {
      setFields({ ...fields, [name]: value });
    }
  };

  const handleClose = (incoming) => {
    if (incoming === 1) {
      navigate("/login");
    } else {
      setLoaderror(0);
    }
  };

  const handleSelected = (incoming) => {
    setFields({ ...fields, gender: incoming.socval });
  };

  const handleRegSelected = (incoming) => {
    setFields({ ...fields, rgcode: incoming.socval });
  };

  const [social, setSocial] = useState(false);
  const [dailvalue, setDailvalue] = useState("+234");

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleDailCode = (e, incoming) => {
    setDailvalue(incoming.code);
    setFields({ ...fields, dailcode: incoming.id });
  };

  return (
    <div className="bnw-login-contain">
      <div className="signinContain py-5">
        {loaderror === 1 ? (
          <LoadVeriy type="spin" text="Loading..." />
        ) : loaderror === 2 ? (
          <Error closeOverlay={handleClose} message={messageStatus} icon="2" />
        ) : loaderror === 5 ? (
          <Error
            closeOverlay={handleClose}
            message={"Registration Successful! Close the modal to Login"}
            icon={9}
          />
        ) : (
          ""
        )}

        <form className="bnw-registe-body" onSubmit={handleSubmit}>
          <div className="bnw-lock-icon-container">
            <RiLock2Fill id="bnw-lock-icon" />
          </div>
          <span>Sign Up</span>

          <div className="bnw-ligin-email-container">
            <input
              className="bnw-ligin-password"
              name="fname"
              placeholder={t("nameplacehold")}
              value={fields.fname}
              type="text"
              onChange={handleLoginChange}
              required
            />
            {<IoIosPerson id="visib-icon" />}
          </div>

          <div className="bnw-ligin-email-container">
            <input
              className="bnw-ligin-password"
              name="email"
              placeholder={t("emailaddressplacehold")}
              value={fields.email}
              type="email"
              onChange={handleLoginChange}
              required
            />
            {<IoIosMail id="visib-icon" />}
          </div>

          {/* <div className="bnw-ligin-email-container">
            <input
              className="bnw-ligin-password"
              name="agnc_name"
              placeholder={t("agencyname")}
              value={fields.agnc_name}
              type="text"
              onChange={handleLoginChange}
              required
            />
            {<BsBuilding id="visib-icon" />}
          </div> */}

          <div className="bnw-ligin-email-container">
            <div className="dailcode-select-container">
              <input
                readOnly={true}
                required
                value={dailvalue}
                onFocus={handleSocialFocus}
                id="bnw-phone-drpdwn"
              />
              <FaChevronDown
                id="tel-chevron-down"
                onClick={handleSocialFocus}
              />
              {social && (
                <div
                  onMouseLeave={handleSocialFocus}
                  className="bnw-dailer-drpdwn"
                >
                  {dailingCode.map((item, key) => (
                    <span
                      onClick={(e) => handleDailCode(e, { code: item.code })}
                      key={key}
                    >
                      {item.code}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <input
              className="bnw-ligin-password"
              name="phone"
              placeholder="80123549586"
              value={fields.phone}
              type="tel"
              onChange={handleLoginChange}
              required
            />
            {<MdContactPhone id="visib-icon" />}
          </div>

          {/* <div className="bnw-ligin-email-container">
            <input
              className="bnw-ligin-password"
              name="dob"
              placeholder={t("dateofbirth")}
              value={fields.dob}
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              onChange={handleLoginChange}
              required
            />
          </div> */}

          <div className="w-full flex items-center justify-center flex-col gap-1">
            <div className="bnw-doube-content-box">
              <Singleselect
                icon={1}
                socialjs={GenderJson}
                selectedSocVal={handleSelected}
                placeholder={t("gender")}
              />

              {/* <Singleselect
                icon={2}
                socialjs={RegionsJson}
                selectedSocVal={handleRegSelected}
                placeholder={t("region")}
              /> */}

              <div className="bnw-ligin-email-container">
                <input
                  className="bnw-ligin-password"
                  name="pcc"
                  placeholder={t("pcc")}
                  value={fields.pcc}
                  type="text"
                  onChange={handleLoginChange}
                  required
                />
                {<BsBuilding id="visib-icon" />}
              </div>
            </div>
            {errornotice === 1 ? (
              <span className="text-[red] text-[11px]">
                PCC should not be more than 4 characters
              </span>
            ) : errornotice === 2 ? (
              <span className="text-[red] text-[11px]">Invalid PCC</span>
            ) : (
              <></>
            )}
          </div>

          <div className="bnw-doube-content-box">
            <div className="bnw-ligin-email-container">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                className="bnw-ligin-password"
                name="password"
                placeholder={t("password")}
                value={fields.password}
                type={visible ? "password" : "text"}
                onChange={handleLoginChange}
                required
              />
              {visible ? (
                <MdOutlineVisibility id="visib-icon" onClick={handleVisIcon} />
              ) : (
                <MdOutlineVisibilityOff
                  id="visib-icon"
                  onClick={handleVisIcon}
                />
              )}
            </div>

            <div className="bnw-ligin-email-container">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                className="bnw-ligin-password"
                name="confpass"
                placeholder={t("confpassword")}
                value={fields.confpass}
                type={visibleAlt ? "password" : "text"}
                onChange={handleLoginChange}
                required
              />
              {visibleAlt ? (
                <MdOutlineVisibility
                  id="visib-icon"
                  onClick={handleVisIcon_alt}
                />
              ) : (
                <MdOutlineVisibilityOff
                  id="visib-icon"
                  onClick={handleVisIcon_alt}
                />
              )}
            </div>
          </div>

          <div className="checkbox-container">
            <input type="checkbox" id="checkbox-register" required />
            <span>
              {t("termscon")}{" "}
              <b>
                <Link to="/">{t("conditions")}</Link>
              </b>
            </span>
          </div>

          <input
            disabled={view ? true : false}
            className={`${
              view ? "bg-[#41414273]" : "bg-[#e50000]"
            } outline-none border border-custom-red text-[white] w-[90%]  min-w-[260px] py-[10px] px-[15px] text-[15px] font-semibold  mt-[20px] pointer`}
            type="submit"
            value={t("submit")}
          />
        </form>
      </div>
    </div>
  );
};

export default Register;
