import React from 'react'
import "../../Assets/Styles/style.css"

const Footer = () => {
  return (
    <div className='footerContainerSup'>
        <span id="footer-text">
          Copyright &#169; <b>2022 Sabre Travel Network</b>. All rights Reserved v.2.0
      </span>
    </div>
  )
}

export default Footer