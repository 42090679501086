export default function formatDateTime(inputDate) {
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const formattedDate = `${ordinalSuffix(day)}, ${month} ${year}`;
  return formattedDate;
}

const ordinalSuffix = (day) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const relevantDigits = day > 10 && day < 20 ? 0 : day % 10;

  return day + (suffixes[relevantDigits] || suffixes[0]);
};
