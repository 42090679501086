import React, { useState, useEffect } from "react";
import { IoChevronDown } from "react-icons/io5";
import "./Singleselect.css";
import { useSelector } from "react-redux";
import RegionsJson from "../../Pages/Registerpage/RegionJson";

const Singleselect = ({ selectedSocVal, placeholder, socialjs }) => {
  const { userdata } = useSelector((state) => state.datareponse);

  let users = userdata;

  useEffect(() => {
    setSocialvalue((RegionsJson.find(regname => regname.value === users.region_code)).name)

    selectedSocVal(users.region_code)
  }, [users.region_code])
  

  const [socialvalue, setSocialvalue] = useState("");
  const [social, setSocial] = useState(false);

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleSocialClick = (e, incoming) => {
    setSocialvalue(incoming.soc);
    setSocial(!social);
    selectedSocVal(incoming.rn);
  };

  return (
    <div className="w-[70%] min-w-[190px] outline-none text-[13px] font-[500] relative">
      <input
        disabled={users.region_code === "" ? false : true }
        value={socialvalue}
        onClick={handleSocialFocus}
        placeholder={placeholder}
        id="bnw-firnamee-box"
      />
      <IoChevronDown id="chevron-down" />
      {social && (
        <div onMouseLeave={handleSocialFocus} className="bnw-regionn-drpdwn">
          {socialjs.map((item, key) => (
            <span
              onClick={(e) =>
                handleSocialClick(e, { soc: item.name, rn: item.region_code })
              }
              key={key}
            >
              {item.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Singleselect;
