import "./App.css";
import React, { useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Routes/privateRoute";
import Dashboard from "./Pages/dashboard";
import Loginpg from "./Pages/Login";
import Navbar from "./Layouts/NavBar/Navbar";
import Footer from "./Layouts/Footer/FooterSup";
import Landingpage from "./Pages/Landingpage/Landingpage";
import Register from "./Pages/Registerpage/Register";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ActivationPage from "./Pages/ActivationPage";

const App = () => {
  const [currpage, setCurrpage] = useState();
  const handleCurrntpage = (incoming) => {
    setCurrpage(incoming);
  };

  return (
    <Suspense fallback="loading">
      <Router>
        <div className="app-container-bnw">
          <Navbar currentpage={currpage} />
          <div className="appcontainer">
            <Routes>
              <Route
                exact
                path="/"
                element={<Landingpage currentpage={handleCurrntpage} />}
              />
              <Route
                exact
                path="/login"
                element={<Loginpg currentpage={handleCurrntpage} />}
              />
              <Route
                exact
                path="/register"
                element={<Register currentpage={handleCurrntpage} />}
              />
              <Route
                path="/activation/:activation_token"
                element={<ActivationPage />}
              />
              <Route path="/" element={<PrivateRoute />}>
                <Route
                  path="/dashboard"
                  element={<Dashboard currentpage={handleCurrntpage} />}
                />
              </Route>
            </Routes>
          </div>
          <Footer />
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Router>
    </Suspense>
  );
};

export default App;
