import React from 'react';
import ReactLoading from 'react-loading';
import './Sloading.css'
 
const SLoader = ({ type, color, text, loggedin }) => (
    <div className={loggedin === 1 ? "sloadercontainer" : "sloadercontainer_alt"}>
        <div className="sloadingBox">
            <ReactLoading type={type} color={'#e50000'} height={'70%'} width={'70%'} />
            <span id="Loadingtag">{text}</span>
        </div>
    </div>
);
 
export default SLoader;