import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { NavLink, Link as Links } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import fr from "../../Assets/Images/fr.svg";
import en from "../../Assets/Images/us.svg";

const Navbar = ({ currentpage }) => {
  const { t, i18n } = useTranslation();
  const [view, setView] = useState(false);
  const [showlang, setShowlang] = useState(false);
  const [visible, setVisible] = useState("en");
  const [mylang, setMylang] = useState(true);

  const handleMenuClick = () => {
    setView(!view);
  };

  useEffect(() => {
    i18n.changeLanguage(visible);
  }, [visible]);

  const handleMenuIconsClick = () => {
    setView(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setVisible(lng);
  };

  const handleShow = () => {
    setShowlang(true);
  };

  const handleHide = () => {
    setShowlang(false);
  };

  let flags = {
    en: en,
    fr: fr,
  };

  const handleLanguage = () => {
    i18n.changeLanguage(mylang ? "fr" : "en");
    setMylang(!mylang);
  };

  return (
    <div className="navbarContian">
      <div className="navbarcontain-left">
        <NavLink to="/" className="navbar-icon">
          <div id="imgIcon" />
        </NavLink>
        <span>Sabre CWA Reward Plus</span>
      </div>

      {currentpage !== 2 ? (
        <ul className="navbarcontain-right">
          {currentpage === 1 ? (
            ""
          ) : (
            <li>
              <Link
                id="myidlink"
                to="top-bars"
                spy={true}
                smooth={true}
                activeClass="actie"
              >
                {t("Commentparticiper")}
              </Link>
            </li>
          )}
          {currentpage === 1 ? (
            ""
          ) : (
            <li>
              <Link
                id="myidlink"
                to="terms-and-conditions"
                spy={true}
                smooth={true}
              >
                {t("Termesetconditions")}
              </Link>
            </li>
          )}
          <li>
            <Links id="myidlink" to="./login">
              {t("Seconnecter")}
            </Links>
          </li>
          <li>
            <Links id="myidlink" to="./register">
              {t("S'inscrire")}
            </Links>
          </li>
          <li
            id="myidlink"
            className="flex items-center gap-1"
            onMouseEnter={handleShow}
          >
            <img src={flags[visible]} width={14} alt="lang" />
            {t("langue")}
          </li>

          {showlang && (
            <div
              onMouseLeave={handleHide}
              className="flex flex-col top-[50px] right-0 w-[8%] absolute bg-[white] p-0.5 shadow"
            >
              <div
                className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 border hover:bg-[grey] hover:text-[white]"
                onClick={() => changeLanguage("en")}
              >
                <img src={en} width={14} alt="lang" />
                English
              </div>
              <div
                className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 hover:bg-[grey] hover:text-[white]"
                onClick={() => changeLanguage("fr")}
              >
                <img src={fr} width={14} alt="lang" />
                French
              </div>
            </div>
          )}
        </ul>
      ) : (
        <ul className="navbarcontain-right">
          <li
            id="myidlink"
            className="flex items-center gap-1"
            onMouseEnter={handleShow}
          >
            <img src={flags[visible]} width={14} alt="lang" />
            {t("langue")}
          </li>

          {showlang && (
            <div
              onMouseLeave={handleHide}
              className="flex flex-col top-[50px] right-0 w-[8%] absolute bg-[white] p-0.5 shadow"
            >
              <div
                className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 border hover:bg-[grey] hover:text-[white]"
                onClick={() => changeLanguage("en")}
              >
                <img src={en} width={14} alt="lang" />
                English
              </div>
              <div
                className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 hover:bg-[grey] hover:text-[white]"
                onClick={() => changeLanguage("fr")}
              >
                <img src={fr} width={14} alt="lang" />
                French
              </div>
            </div>
          )}
        </ul>
      )}

      <ul className="navbar_language">
        <li
          id="myidlink"
          className="flex items-center gap-1"
          onMouseEnter={handleShow}
        >
          <img src={flags[visible]} width={14} alt="lang" />
          <span className="lang_text">{t("langue")}</span>
        </li>

        {showlang && (
          <div
            onMouseLeave={handleHide}
            className="flex flex-col top-[50px] right-0 w-[18%] absolute bg-[white] p-0.5 shadow"
          >
            <div
              className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 border hover:bg-[grey] hover:text-[white]"
              onClick={() => changeLanguage("en")}
            >
              <img src={en} width={14} alt="lang" />
              English
            </div>
            <div
              className="text-[black] text-[13px] flex gap-1 align-center cursor-pointer py-1 px-2 hover:bg-[grey] hover:text-[white]"
              onClick={() => changeLanguage("fr")}
            >
              <img src={fr} width={14} alt="lang" />
              French
            </div>
          </div>
        )}
      </ul>

      {view && (
        <ul className="hover-container">
          <li onClick={handleMenuIconsClick}>
            <Link
              to="how-to-participate"
              activeClass="active"
              spy={true}
              smooth={true}
            >
              {t("Commentparticiper")}
            </Link>
          </li>
          <li>
            <Link
              id="my-idlink"
              onClick={handleMenuIconsClick}
              to="terms-and-conditions"
              spy={true}
              smooth={true}
            >
              {t("Termesetconditions")}
            </Link>
          </li>
          <li>
            <NavLink id="my-idlink" onClick={handleMenuIconsClick} to="/login">
              {t("Seconnecter")}
            </NavLink>
          </li>
          <li>
            <NavLink
              id="my-idlink"
              onClick={handleMenuIconsClick}
              to="/register"
            >
              {t("S'inscrire")}
            </NavLink>
          </li>

          <li
            id="my-idlink"
            className="flex items-center gap-1"
            onClick={handleLanguage}
          >
            <img src={flags[visible]} width={14} alt="lang" />
            {t("langue")}
          </li>
        </ul>
      )}

      {currentpage !== 2 ? (
        <FiMenu id="landpg-menubar" onClick={handleMenuClick} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navbar;
