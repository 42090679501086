const RegionsJson = [
  {
    ID: 1,
    name: "MALI",
    value: "ML",
  },
  {
    ID: 2,
    name: "GABON",
    value: "GA",
  },
  {
    ID: 3,
    name: "COTE D’IVOIRE",
    value: "CI",
  },
  {
    ID: 4,
    name: "SENEGAL",
    value: "SN",
  },
  {
    ID: 5,
    name: "MAURITANIA",
    value: "MR",
  },
  {
    ID: 6,
    name: "BENIN",
    value: "BJ",
  },
  {
    ID: 7,
    name: "TOGO",
    value: "TG",
  },
  {
    ID: 8,
    name: "NIGER",
    value: "NE",
  },
  {
    ID: 9,
    name: "LIBERIA",
    value: "LR",
  },
  {
    ID: 10,
    name: "CAMEROON",
    value: "CM",
  },
  {
    ID: 11,
    name: "BURKINA FASO",
    value: "BF",
  },
  {
    ID: 12,
    name: "GHANA",
    value: "GH",
  },
  {
    ID: 13,
    name: "NIGERIA",
    value: "NG",
  },
];

export default RegionsJson;
