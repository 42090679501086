import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Landingpage.css";

const Landingpage = ({ currentpage }) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    currentpage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectSabre = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="landingpage-container">
      <section id="top-bars" className="landpage-picture">
        <div className="landpage-picture-overlay" />
      </section>

      <section id="how-to-participate">
        <div className="how-to-participate-top">
          {/* <div className='how-to-participate-image' /> */}
          <div className="how-to-participate-image" />
          <div className="how-to-participate-text">
            <span>{t("Commentparticiper")}</span>
            <ul className="htp-body">
              <li>
                <b>
                  <Link to="/login">{t("Créez")}</Link>
                </b>{" "}
                {t("ou")}{" "}
                <b>
                  <Link to="/register">{t("Seconnecter")}</Link>
                </b>{" "}
                {t("àvotrecompte")}{" "}
              </li>

              <li>{t("Unefoisconnecté")}</li>

              <li>{t("Indiquezvotre")}</li>
              <li>{t("Connectez-vous")}</li>
            </ul>
          </div>
        </div>

        <div className="how-to-participate-top">
          <div className="how-to-participate-text">
            <span>{t("sabreworkspaceheading")}</span>
            <ul className="htp-bodys">
              <span>{t("sabreworkspacetext")}</span>

              <span>
                <div
                  className="py-[8px] px-[10px] border min-w-[100px] max-w-[150px]  text-[13px]
                    font-[700] text-center cursor-pointer bg-[#e80000] text-[#fff] hover:bg-[#fff] hover:text-[#e80000]"
                  onClick={() => redirectSabre("https://www.sabrecwa.com/")}
                >
                  {t("Apprendre")}
                </div>
              </span>
            </ul>
          </div>
          <div className="how-to-participate-image1" />
        </div>
      </section>

      <section id="terms-and-conditions">
        <span>{t("Termesetconditions")}</span>
        <span>
          En participant à la promotion Book & Win, vous acceptez les termes et
          conditions ci-après :
        </span>

        <ul className="terms-and-condition-list">
          <li>{t("tremsandcon1")}</li>
          <li>{t("tremsandcon3")}</li>
          <li>{t("tremsandcon4")}</li>
          <li>{t("tremsandcon5")}</li>
          <li>{t("tremsandcon6")}</li>
          <li>{t("tremsandcon7")}</li>
          <li>{t("tremsandcon8")}</li>
          <li>{t("tremsandcon9")}</li>
          <li>{t("tremsandcon10")}</li>
          {/* <li>{t("tremsandcon11")}</li> */}
          <li>{t("tremsandcon12")}</li>
          <li>{t("tremsandcon2")}</li>
          <li>{t("tremsandcon13")}</li>
          {/* <li>{t("tremsandcon14")}</li> */}
        </ul>

        <Link to="/register" id="register-now">
          {t("Rejoignezletrain")}
        </Link>
      </section>
    </div>
  );
};

export default Landingpage;
