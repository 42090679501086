import React, {useState} from 'react'
import './Buttonpagenate.css'

const ButtonPagenate = ({updatedPageNum, pageCount, pageNumber, previousLabel, nextLabel}) => {
    const [initialbut, setInitialbut] = useState(1)
    // const [pageNumber, setPageNumber] = useState(setpageNumber,);


  const handleClick = (e) =>{
    // console.log(e.target.name)
    if(e.target.name === "but1"){
        setInitialbut(1)
        updatedPageNum(0)
    }

    if(e.target.name === "but2"){
        setInitialbut(2)
        updatedPageNum(1)
    }

    if(e.target.name === "but3"){
        setInitialbut(3)
        updatedPageNum(2)
    }

    if(e.target.name === "but4"){
        setInitialbut(4)
    }

    if(e.target.name === "but5"){
        setInitialbut(5)
    }

    if(e.target.name === "next"){
        if(pageCount > 1 && pageNumber < pageCount-1){
            setInitialbut(6)
            updatedPageNum(pageNumber + 1)
            setInitialbut(initialbut + 1)
        } 
    }

    if(e.target.name === "prev"){
        if(pageCount !== 0 && pageNumber !== 0){
            setInitialbut(7)
            updatedPageNum(pageNumber - 1)
            setInitialbut(initialbut - 1)
        }
    }
}
  return (
    <div className='toggle-button'>
        <button name='prev' onClick={handleClick} className='nav-button'> {previousLabel} </button>
        <button name='but1' onClick={handleClick} className={initialbut === 1 ? 'nav-button activess' : 'nav-button'}> 1 </button>
        <button name='but2' onClick={handleClick} className={initialbut === 2 ? 'nav-button activess' : 'nav-button'}> 2 </button>
        {/* <button name='but3' onClick={handleClick} className={initialbut === 3 ? 'nav-button activess' : 'nav-button'}> 3 </button> */}
        {pageCount > 2 && <>
            <button name='but4' onClick={handleClick} className={initialbut > 3 && initialbut < pageCount ? 'nav-button activess' : 'nav-button'}> ... </button>
            <button name='but5' onClick={handleClick} className={initialbut === pageCount ? 'nav-button activess' : 'nav-button'}> {pageCount} </button>
        </>}
        <button name='next' onClick={handleClick} className='nav-button'> {nextLabel} </button>
    </div>
  )
}

export default ButtonPagenate