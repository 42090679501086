import React from 'react'
import '../../Assets/Styles/style.css'
import { RiCloseCircleFill, RiSignalWifiErrorFill } from "react-icons/ri";
import { FaRegThumbsUp } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { GiSandsOfTime} from "react-icons/gi";
// import ErrorIcon from '../assets/error.svg';
import { Link } from 'react-router-dom';

const Error = ({closeOverlay, message, icon, isTimeout}) => {
    // const [close, setClose] = useState(false)
    const Iconobj = {
      1 : <FaRegThumbsUp id='confIcon'/>,
      2 : <TiCancel id='errorIcon'/>,
      3 : <GiSandsOfTime id='errorIcon'/>,
      4 : <div id='serverError'/>,
      5 : <RiSignalWifiErrorFill id='serverError'/>,
      9 : <FaRegThumbsUp id='confIcon'/>
    }
    const handleClose = () =>{
        // setClose(!close)
        if(icon === 5){
          closeOverlay(1)
        }else{
          closeOverlay(0)
        }
    }

    const handleClick = () =>{

    }

  return (
    <div className='errorContainer'>
        <div className='errorSubContain'>
            <div className='ClsiconContain'><RiCloseCircleFill onClick={handleClose} id='closeIcon'/></div>
            <div className='iconContainer'>{Iconobj[icon]}</div>
            <span id="errorInfo">{message}</span>

            {isTimeout && <button id='bnw-logout-button' onClick={handleClick}><Link to="/" className='bnw-logout'>Log In</Link></button>}
        </div>
    </div>
  )
}

export default Error
