const GenderJson = [
    {
        ID: 1,
        name: 'Male',
        value: 'M'
    },
    {
        ID: 2,
        name: 'Female',
        value: 'F'
    }
]

export default GenderJson