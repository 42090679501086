const dailingCode = [
    {
        code: '+234',
        country: 'Nigeria',
        id: 1
    },
    {
        code: '+229',
        country: 'Benin',
        id: 2
    },
    {
        code: '+237',
        country: 'Cameroonn',
        id: 3
    },
    {
        code: '+225',
        country: 'Cote d Ivoire',
        id: 4
    },
    {
        code: '+241',
        country: 'Gabon',
        id: 5
    },
    {
        code: '+233',
        country: 'Ghana',
        id: 6
    },
    {
        code: '+231',
        country: 'Liberia',
        id: 7
    },
    {
        code: '+223',
        country: 'Mali',
        id: 8
    },
    {
        code: '+222',
        country: 'Mauritania',
        id: 9
    },
    {
        code: '+227',
        country: 'Niger',
        id: 10
    },{
        code: '+221',
        country: 'Senegal',
        id: 11
    },
    {
        code: '+228',
        country: 'Togo',
        id: 12
    },
    {
        code: '+226',
        country: 'Burkina Faso',
        id: 13
    }
    
]

export default dailingCode