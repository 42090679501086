export const getCurrentTab = (currenttab) => ({
  type: "CurrentTab",
  payload: currenttab,
});

export const getPagestatus = (pagestatus) => ({
  type: "PageStatus",
  payload: pagestatus,
});

export const captureSidebarClicks = (sidebarclicks) => ({
  type: "Side_Bar_Click",
  payload: sidebarclicks,
});

export const claimsTabstatus = (claimsbarststus) => ({
  type: "Claims_Bar_Status",
  payload: claimsbarststus,
});

export const modalToggle = (togglemodal) => ({
  type: "ToggleModal",
  payload: togglemodal,
});

export const loadingData = (loading) => ({
  type: "Loding_Modal",
  payload: loading,
});

export const setsidebar = (tabstate) => ({
  type: "Tab_State",
  payload: tabstate,
});

export const captureModalOverlay = (tabstate) => ({
  type: "Add_New_Modal",
  payload: tabstate,
});
