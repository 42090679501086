import React, {useState} from 'react'
// import { IoChevronDown } from "react-icons/io5";
import './Singleselect.css'
import { FaPeopleArrows } from 'react-icons/fa';
import { GiWorld } from 'react-icons/gi';

const Singleselect = ({selectedSocVal, icon, placeholder, socialjs}) => {
    // console.log(socialjs)
    const [socialvalue, setSocialvalue] = useState("")
    const [social, setSocial] = useState(false)

    const handleSocialFocus = () =>{
        setSocial(!social)
    }
    
    const handleSocialClick = (e, incoming) =>{
        setSocialvalue(incoming.socname)
        setSocial(!social)
        selectedSocVal(incoming)
    }

    const handleValueChange = (e) =>{
        setSocialvalue(e.target.value)
        // console.log(e.target.value)
    }
    // id="bnw-dropdown-box"
  return (
    <div className='sing-selectt-container'>
        <input value={socialvalue} onClick={handleSocialFocus} placeholder={placeholder} onChange={handleValueChange} className='bnw-ligin-password' type='text' required readOnly="readonly"/>
            {icon === 1 ? <FaPeopleArrows id='visib-icon' onClick={handleSocialFocus}/> : <GiWorld id='visib-icon' onClick={handleSocialFocus}/>}
            {social && <div onMouseLeave={handleSocialFocus} className='bnw-regionn-drpdwn'>{socialjs.sort((a, b) => a.name > b.name ? 1 : -1).map((item, key) => (
                <span onClick={e => handleSocialClick(e, {socname: item.name, socval: item.value, socid: item.ID})} key={key}>{item.name}</span>
            ))}
            </div>}
    </div>
  )
}

export default Singleselect